<template>
    <e-charts v-if="people" :options="orgchart" :autoresize="true" @click.native.alt.shift="switchType"/>
</template>

<!-- vue-echart doesn't work with typescript, so we use JS components -->
<script>
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import ECharts from 'vue-echarts';
import 'echarts/lib/chart/tree';
import 'echarts/lib/component/tooltip';
import {getPeople} from '@/structure/store/endpoints';

export default {
    components: {
        ECharts,
    },

    data() {
        return {
            people: null,
            useRadial: localStorage.getItem('hermes-radial') === 'true',
        };
    },

    computed: {
        orgchart() {
            return {
                tooltip: {
                    trigger: 'item',
                    triggerOn: 'mousemove',
                    formatter(params) {
                        let text = `
                            <strong>${params.data.name}</strong><br/>
                            <em>${params.data.value}</em>`;

                        if (params.data.photoUri) {
                            text = `<p class="text-center"><img width="100" src="${params.data.photoUri}"/></p>${text}`;
                        }

                        return text;
                    },
                },
                series: [
                    {
                        type: 'tree',
                        layout: this.useRadial ? 'radial' : 'orthogonal',
                        orient: 'LR',
                        roam: true,
                        symbolSize: 20,
                        symbol: 'emptyRect',

                        lineStyle: {
                            color: 'silver',
                            width: 1,
                            curveness: 0.5,
                        },

                        label: {
                            position: 'left',
                            verticalAlign: 'middle',
                            align: 'right',
                            fontSize: 12,
                        },

                        leaves: {
                            label: {
                                position: 'right',
                                verticalAlign: 'middle',
                                align: 'left',
                            },
                        },

                        data: this.people,
                    },
                ],
            };
        },
    },

    async mounted() {
        this.people = await getPeople();
    },

    methods: {
        switchType() {
            this.useRadial = !this.useRadial;
            localStorage.setItem('hermes-radial', this.useRadial);
        },
    },
};
/* eslint-enable @typescript-eslint/explicit-function-return-type */
</script>

<style scoped>

</style>
