import {api} from '@/shared/api';
import {EchartTreeItem, EchartTreeItemPerson} from '@/structure/store/EchartTreeItem';

interface SubUnit {
    name: string;
    description: string;
    subUnits: SubUnit[];
}

function fromSubunits(data: SubUnit): EchartTreeItem {
    if (data.subUnits) {
        return {
            name: data.name,
            value: data.description,
            children: data.subUnits.map(fromSubunits),
        };
    } else {
        return {
            name: data.name,
            value: data.name,
        };
    }
}

interface Person {
    fullName: string;
    jobTitle: string;
    photoUri: string;
    managing: Person[];
}

function fromPeople(data: Person): EchartTreeItemPerson {
    if (data.managing.length) {
        return {
            name: data.fullName,
            value: data.jobTitle,
            photoUri: data.photoUri,
            children: data.managing.map(fromPeople),
        };
    } else {
        return {
            name: data.fullName,
            value: data.jobTitle,
            photoUri: data.photoUri,
        };
    }
}

export async function getUnits(): Promise<EchartTreeItem[]> {
    const {data} = await api<SubUnit[]>({
        method: 'get',
        url: 'OrganizationChart/organizationalUnits',
    });

    return data.map(fromSubunits);
}

export async function getPeople(): Promise<EchartTreeItem[]> {
    const {data} = await api<Person[]>({
        method: 'get',
        url: 'OrganizationChart/employees',
    });

    return data.map(fromPeople);
}
