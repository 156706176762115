








import {Component, Vue} from 'vue-property-decorator';
import AppScreen from '@/layout/AppScreen.vue';

@Component({
    components: {
        AppScreen,
    },
})
export default class StructureScreen extends Vue {

}
