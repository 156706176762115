<template>
    <article>
        <e-charts v-if="units" :options="orgchart" :autoresize="true"/>
    </article>
</template>

<!-- vue-echart doesn't work with typescript, so we use JS components -->
<script>
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import ECharts from 'vue-echarts';
import 'echarts/lib/chart/tree';
import 'echarts/lib/component/tooltip';
import {getUnits} from '@/structure/store/endpoints';

export default {
    components: {
        ECharts,
    },

    data() {
        return {
            units: null,
        };
    },

    computed: {
        orgchart() {
            return {
                tooltip: {
                    trigger: 'item',
                    triggerOn: 'mousemove',
                    formatter: '{b}<br/><strong>{c}</strong>',
                },
                series: [
                    {
                        type: 'tree',
                        symbolSize: 20,
                        right: '40%',

                        label: {
                            normal: {
                                position: 'left',
                                verticalAlign: 'middle',
                                align: 'right',
                                fontSize: 16,
                            },
                        },

                        leaves: {
                            label: {
                                normal: {
                                    position: 'right',
                                    distance: 16,
                                    // verticalAlign: 'bottom',
                                    align: 'left',
                                },
                            },
                        },

                        data: this.units,
                    },
                ],
            };
        },
    },

    async mounted() {
        this.units = await getUnits();
    },
};
/* eslint-enable @typescript-eslint/explicit-function-return-type */
</script>

<style scoped>

</style>
